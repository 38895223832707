import { graphql } from 'gatsby'
import React, {useState, useEffect} from 'react'
import Seo from '../components/Seo'

export default function Contact({data}) {
  
  const [notificationText, setNotificationText] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState()

  const handleOnKeyUp = (e) => {
    const target = e.target

    if(target.name === 'firstName'){
      setFirstName(target.value)
    }
    if(target.name === 'lastName'){
      setLastName(target.value)
    }
    if(target.name === 'email'){
      setEmail(target.value)
    }
    if(target.name === 'message'){
      setMessage(target.value)
    }

    setFormData({
      firstName, 
      lastName,
      email, 
      message
    })
    
  }

  const clearForm = () => {
    console.log('clearForm')
    setFirstName('')
    setLastName('')
    setEmail('')
    setMessage('')
  }

  const showNotification = () => {
    console.log('showNotification')
    setNotificationText('Thanks for your message. I will be in touch soon to discuss your enquiry. ')
    clearForm()
    setTimeout(() => {
      setNotificationText('')
    }, 6000);
  }

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        ...formData,
        }),
      })
      .then(() => showNotification())
      .catch((error) => alert(error));
  };
  
  

  return (
    <div className='contact'>
      <Seo 
        description={'Bespoke dog walking service covering Chapel Allerton and surrounding areas of North Leeds.'}
        image={'/og.jpg'}
        title={'Contact | The Enriched Dog | Dog Walking Leeds'}
      />
      <div className="intro">
        <h1>Contact</h1>
        <div className="text heading">
          <div className="inner">
            <ul>
              <li>Email - <a href="mailto:lucy@tedwalks.co.uk">lucy@tedwalks.co.uk</a></li>
              <li>Telephone - <a href="tel:+447841193405">07841193405</a></li>
            </ul>
          </div>
        </div>
        <div className="text heading">
          <div className="inner">
          Alternatively send us a message by filling in the form below
          </div>
        </div>
      </div>
      <div className="form">
        <form id="contact" name="contact" method="POST" data-netlify="true" onSubmit={ handleSubmit }>
          <input type="hidden" name="form-name" value="contact" />
          <input onChange={(e) => handleOnKeyUp(e)} required type="text" name="firstName" id="firstName" value={firstName} placeholder='first name' />
          <input onChange={(e) => handleOnKeyUp(e)} required type="text" name="lastName" id="lastName" value={lastName} placeholder='last name' />
          <input onChange={(e) => handleOnKeyUp(e)} required type="email" name="email" id="email" value={email} placeholder='email address' />
          <textarea onChange={(e) => handleOnKeyUp(e)}  name="message" id="message" value={message} placeholder='enter your message here...' />
          <input type="submit" value="Submit" className='button small pink' />
        </form>
        {notificationText !== '' ? <div className="notification">{notificationText}</div> : '' }
      </div>
    </div>
  )
}


export const ContactQuery = graphql`
  query ContactQuery { 
    og: imageSharp(id: {eq: "a330c820-bb09-510b-8422-a087d0fda198"}) {
      original {
        src
      }
    }
  }
`